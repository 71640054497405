import React from "react";
import ReactDOM from "react-dom";
import "antd/dist/antd.css";
import "./index.css";
import App from "./App";
import { Provider } from "react-redux";
import store from "./store";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import UnAuthorizedUser from "./components/ValidationPages/unAuthorizedUser";
import InvalidDocument from "./components/ValidationPages/invalidDocumnet";
import ResultComponent from "./components/ResultComponent/ResultComponent";


ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <Router>
        <Switch>
          {/* <Route path="/unauthorized">
            <UnAuthorizedUser/>
          </Route> */}
          <Route path="/invalid-document" >
            <ResultComponent title="Document Verification Failed , Invalid Document. " />
          </Route>
          <Route
            path="/unauthorized">
            <ResultComponent title="Security Verification Failed , Unauthorized Access. " />
          </Route>
          <Route path="/no-internet">
            <ResultComponent title="No Internet, Check your internet connection." />
          </Route>
          <Route exact path="/mileslides/:id">
            <App isExternal={false}/>
          </Route>
          <Route exact path="/mileslides/external/:id">
            <App isExternal={true}/>
          </Route>
        </Switch>
      </Router>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);
