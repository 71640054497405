import React, { useState } from "react";
import "./App.css";
import { AuthenticateDocument } from "./utils/AuthenticateDocument";
import MainLayout from "./components/MainLayout";
import External from "./components/ExternalUser";

function App({ isExternal }) {
  const [isAuthenticating, setIsAuthenticating] = useState(true);

  if (isExternal) {
    return <External/>;
  }
  else {
    if (isAuthenticating) { // isAuthenticating
      return <AuthenticateDocument setIsLoading={setIsAuthenticating} />
    }
    else {
      return <MainLayout />;
    }
  }

}

export default App;
