import { Input } from "antd";
import { message } from 'antd';
import { CopyOutlined } from "@ant-design/icons";
import "antd/dist/antd.css";
import { useParams } from "react-router-dom/cjs/react-router-dom";

export const ShareInput = ({ contentToggle, privacy }) => {
  const { id } = useParams();
  const sharedUrl = `https://slides.workw.com/mileslides${privacy === 0 ? "/external" : ""}/${id}`;
  const copyLinkIcon = <CopyOutlined onClick={() => { navigator.clipboard.writeText(sharedUrl); message.success("Link copied to clipboard") }} />;
  return (
    <>
      {contentToggle ? (
        <Input placeholder="Add people and groups" />
      ) : (
        <Input size="small" suffix={copyLinkIcon} value={sharedUrl} />
      )}
    </>
  );
};
