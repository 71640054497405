import React, { useRef, useState } from "react";
import { Tooltip, Dropdown, Menu, message, Form, Input, Modal } from "antd";
import importExportIcon from "../../assets/import-export.svg";
import classes from "../Toolbar/Toolbar.module.css";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";
import { slideAction } from "../../store/slideSlice";
import { useParams } from "react-router-dom/cjs/react-router-dom";
import { slideAPI } from "../../store/api";

const ImportExportMenu = () => {
  const { id } = useParams();
  const dispatch = useDispatch();
  const fileInputRef = useRef(null);
  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const slides = useSelector((state) => state.slide.slides);
  const mirrorSlides = useSelector((state) => state.slide.mirrorSlides);
  const documentInformation = useSelector((state) => state.slide.documentInformation);
  let currentSlideId = useSelector((state) => state.slide.currentSlideId);
  const [fileName, setFileName] = useState("");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [form] = Form.useForm();

  const handleMenuClick = (data) => {
    const key = data.key;
    if (key === "import") {
      fileInputRef.current.click();
    } else if (key === "export.slide") {
      const fileName = documentInformation?.name || "Untitled document";
      form.setFieldsValue({ fileName: fileName });
      setFileName(fileName);
      setIsModalVisible(true);
    }
  };

  const handleExport = () => {
    form.validateFields()
      .then(() => {
        try {
          const content = {
            slides: slides,
            mirrorSlides: mirrorSlides,
          };
          const blob = new Blob([JSON.stringify(content, null, 2)], {
            type: "application/json",
          });
          saveAs(blob, `${fileName}.slide`);
          console.log("Slides exported successfully!");
          setIsModalVisible(false);
        } catch (error) {
          console.error("Error exporting slides:", error);
        }
      })
      .catch((validationError) => {
        console.error("Validation failed:", validationError);
        message.error("Please enter a valid file name.");
      });
  };


  const handleImport = async (event) => {
    try {
      const file = event.target.files[0];
      if (file) {
        const text = await file.text();
        const importedSlides = JSON.parse(text);
        Modal.confirm({
          title: "Confirm",
          content: "Do you want to overwrite?",
          okText: "Yes",
          cancelText: "No",
          onOk: async () => {
            try {
              dispatch(slideAction.updateSlidesData(importedSlides));
              slideAPI.saveImportedDataToDb({
                ...importedSlides,
                currentSlideId: currentSlideId,
                currentElementId: '',
                presentMode: false,
                presentationId:id,
              });
              message.success("Slides imported successfully.");
              console.log("Slides imported successfully!");
            } catch (error) {
              console.error("API call failed:", error);
              message.error("Failed to save data. Please try again.");
            }
          },
          onCancel: () => {
            event.target.value = "";
          },
        });
      }
    } catch (error) {
      console.error("Error importing slides:", error);
    } finally {
      event.target.value = ""; 
    }
  };

  const menu = (
    <Menu onClick={handleMenuClick}>
      <Menu.Item key="import">Import</Menu.Item>
      <Menu.SubMenu key="export" title="Export">
        <Menu.Item key="export.slide">.slide</Menu.Item>
        {/* Add more export options here */}
      </Menu.SubMenu>
    </Menu>
  );

  const handleFileNameChange = (e) => {
    setFileName(e.target.value)
  }

  return (
    <>
      <Tooltip
        overlayInnerStyle={{
          width: "auto",
          minHeight: "20px",
          fontSize: "10px",
          padding: "5px",
          textAlign: "center",
          backgroundColor: "black",
          lineHeight: "10px",
          display: `${isDropdownVisible ? "none" : "flex"}`,
        }}
        placement="bottom"
        title={"Import/Export"}
      >
        <Dropdown
          overlay={menu}
          trigger={["click"]}
          onVisibleChange={(visible) => setIsDropdownVisible(visible)}
        >
          <button className={classes["toolbar-btn"]}>
            <img src={importExportIcon} width={24} alt="Import/Export" />
          </button>
        </Dropdown>
      </Tooltip>
      <input
        type="file"
        accept=".slide"
        style={{ display: "none" }}
        ref={fileInputRef}
        onChange={handleImport}
      />
      <Modal
        title="Set File Name"
        visible={isModalVisible}
        onOk={handleExport}
        onCancel={() => setIsModalVisible(false)}
      >
        <Form form={form}>
          <Form.Item
            label="File Name"
            name="fileName"
            rules={[
              {
                required: true,
                message: "Please enter a file name.",
              },
              {
                pattern: /^[^<>:;,?"*|/\\]+$/,
                message: "File name contains invalid characters.",
              },
            ]}
          >
            <Input
              value={fileName}
              onChange={handleFileNameChange}
            />
          </Form.Item>
        </Form>
      </Modal>
    </>
  );
};

export default ImportExportMenu;
