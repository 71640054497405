import React, { useState, useEffect } from "react";
import Mouse from "../mouse";
import useMousePosition from "../../hooks/useMousePosition";
import socket, {
  addTotalClients,
  connectedClients,
  emitMousePositions,
  removeTotalClients,
  renderMouse,
  emitRoomID
} from '../../Socket/Socket';
import { debounce } from "lodash";
import { useRef } from "react";
import { getWorkWiseAuthToken } from "../../utils/helper";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { useSelector } from "react-redux";

const MouseContainer = () => {

  const { id } = useParams();
  const [mousePositions, setMousePositions] = useState({
    serverX: null,
    serverY: null,
  });
  const [clientSocketID, setClientSocketID] = useState(null);
  const [connectedSocketID, setConnectedSocketID] = useState([]);
  const [user, setUser] = useState({});
  const [numberOfConnectedClients, setNumberOfConnectedClients] = useState(0);
  const { x, y,userName } = useMousePosition();
  const userInfo = useSelector((state) => state.slide.userInformation);

  const debouncedMousePosition = useRef(debounce((x, y, user) => {
    emitRoomID(id)
    setClientSocketID(socket.id);
    emitMousePositions({ id, clientSocketID, x, y, userName: user.name });
  }, 250)).current;

  useEffect(() => {
    debouncedMousePosition(x, y, user);
  }, [x, y]);

  useEffect(() => {
    if (getWorkWiseAuthToken()) {
      let info = JSON.parse(Buffer.from(getWorkWiseAuthToken().split('.')[1], 'base64').toString());
      setUser(info);
    }
  }, []);

  useEffect(() => {
    renderMouse(setConnectedSocketID);
    connectedClients(setConnectedSocketID);
    addTotalClients(setNumberOfConnectedClients);
    removeTotalClients(setNumberOfConnectedClients);
  }, []);

  const { serverX, serverY } = mousePositions;
  return (
    <>
      {connectedSocketID.map((user, index) => {
        return (
          clientSocketID !== user.clientSocketID &&
          (
            <Mouse serverX={user.x} serverY={user.y} index={index} name={user.userName} />
          )
        );
      })}
    </>
  )
};

export default MouseContainer;
