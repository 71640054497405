// MainLayout.js
import React, { useEffect, useState } from "react";
import { Layout } from "antd";
import { Header as MainHeader } from "../../components/Header/Header";
import { SlideContents } from "../../components/Slide/SlideContents/SlideContents";
import Slides from "../../components/Slide/Slides";
import { SpeakerNotes } from "../../components/Slide/SpeakerNotes/SpeakerNotes";
import { Toolbar } from "../../components/Toolbar/Toolbar";
import ContextMenu from "../../ContextMenu/ContextMenu";
import { ChatBox } from "../../components/ChatBox/ChatBox";
import OfflineStatus from "../../components/OfflineStatus/OfflineStatus";
import MouseContainer from "../mousecontainer";
import MasterSlide from "../MasterSlide";
import Loader from "../SkeletonLoader/Loader";
import { useDispatch, useSelector } from "react-redux";
import {
    emitRoomID,
    listenElementAdd,
    listenElementDelete,
    listenShapeTextUpdate,
    listenSlideAdd,
    listenSlideColor,
    listenSlideDelete,
    listenSpeakerNotes,
    listenStyleUpdate,
    listenTextUpdate
} from "../../Socket/Socket";
import { slideAPI } from "../../store/api";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import { slideAction } from "../../store/slideSlice";
const { Sider, Content } = Layout;

const MainLayout = () => {
    const { id } = useParams('id');
    const dispatch = useDispatch();
    const { createPresentationById } = slideAPI;
    const [isContentLoading, setIsContentLoading] = useState(true);
    const isMasterSlide = useSelector((state) => state.slide.isMasterSlide);
    const isReadOnly = useSelector((state) => state.slide.isReadOnly);

    // Handle mouse hover for cursor change
    const handleMousHover = (e) => {
        if (
            e.target.classList.contains("ql-container") ||
            e.target.classList.contains("shape")
        ) {
            e.target.style.cursor = "move";
        }
    };


    useEffect(() => {
        if (id) {
            dispatch(slideAction.setPresentationId(id));
            createPresentationById(id, dispatch, setIsContentLoading);
            emitRoomID(id);
        }
        // Set up socket listeners
        listenSlideAdd(dispatch);
        listenElementAdd(dispatch);
        listenSlideDelete(dispatch);
        listenElementDelete(dispatch);
        listenStyleUpdate(dispatch);
        listenSlideColor(dispatch);
        listenSpeakerNotes(dispatch);
        listenTextUpdate(dispatch);
        listenShapeTextUpdate(dispatch);

        return () => {
        };
    }, [id]);

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key === "Tab") {
                if (isReadOnly) {
                    e.preventDefault();
                    e.stopPropagation();
                }
            }
        };
        document.addEventListener("keydown", handleKeyDown);

        return () => {
            document.removeEventListener("keydown", handleKeyDown);
        };
    }, [isReadOnly]);

    return (
        <div className="App">
            {isContentLoading && <Loader />}
            {!isContentLoading && (
                <>
                    <MainHeader />
                    <div id='offlineStatusBar'>
                        <OfflineStatus />
                    </div>
                    <Toolbar />
                    <Layout id='slide-layout'>
                        <Layout>
                            <Sider className="sidebar-container" width={window.screen.width < 992 ? '100%' : '13.6%'} id='my-sideBar'>
                                <Slides />
                            </Sider>
                            <Content id="mainContainer" className='main-container' style={{ backgroundColor: '#f4f4f5', display: 'flex', justifyContent: 'center', paddingTop: '20px', minHeight: '85vh', overflowY: 'scroll' }} onMouseOver={handleMousHover}>
                                <div id='delete-menu'>
                                    <ContextMenu />
                                </div>
                                {isMasterSlide && (
                                    <div id='masterSlide-Conatiner'>
                                        <MasterSlide />
                                    </div>
                                )}
                                <SlideContents />
                                <div id='speakerNotes'>
                                    {window.screen.width > 992 && <SpeakerNotes />}
                                </div>
                            </Content>
                        </Layout>
                    </Layout>
                    <div id='chatbox-container'>
                        <ChatBox />
                    </div>
                    <div id='mouseContainer'>
                        <MouseContainer />
                    </div>
                </>
            )}
        </div>
    );
};

export default MainLayout;