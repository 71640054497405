import { createSlice } from "@reduxjs/toolkit";
// import { emitSlideAdd, emitSpeakerNotes } from "../Socket/Socket";
import { slideAPI } from "./api";
import { fabClasses } from "@mui/material";

const {
  saveMirrorInDatabase,
  updateSlideBackgroundInDatabase,
  updateElementStyleInDatabase,
  saveCurrentSlideIdInDatabase } = slideAPI;

const initialState = {
  /******Data structure */
  presentationId: "",
  currentSlideId: "",
  // currentElementId: "",
  presentMode: false,
  mirrorSlides: {},
  slides:{},
  documentInformation: {},
  isReadOnly: false,
  userInformation:{},
  darkMode:true,
  isMasterSlide: false,
  masterSlideId:'',
  masterSlideElementId: '',
  deletedElements:{},
  savingData: {
    isSaving: '',
    lastSavedDateTime: "",
    fileSize:0,
  },
  isExternal:false,
  isExternalAllow:false,
};



const slideSlice = createSlice({
  name: "slide",
  initialState,
  reducers: {
    addSlide(slideState, actions) {
      const { slideId, slideData } = actions.payload;
      // if(!slideState.slide){
      //   slideState.slides = {};
      // }
      slideState.slides[slideId] = slideData;
      // slideState.mirrorSlides[slideId] =  "";
      // slideState.currentSlideId = slideId;
      // addNewSlideInDatabase(slideState.presentationId,slideId,slideData);
    }, // in reducer method parameter we get latest updated state 
    updateSlide(state,action)
    {
      const { slideId, slideData } = action.payload;
      state.slides[slideId] = slideData;
    },
    updateSlidesData(state, action) {
      const { slides, mirrorSlides } = action.payload;
      if (slides) {
        state.slides = slides;
        if (mirrorSlides) {
          state.mirrorSlides = mirrorSlides;
        }
      }
    },
    updateIsSaving(state,action){
      state.savingData = {...action.payload};
    },
    setDocumentInformation(state, action) {
      state.documentInformation = action.payload;

      const searchParams = new URLSearchParams(document.location.search)
      const isReadOnlyQuery = searchParams.get("readOnly")
      if (isReadOnlyQuery == "true" || action.payload.rightType == 1) {
        state.isReadOnly = true;
      }
    },
    setIsExternal(state,action){
      state.isExternal = action.payload;
    },
    setIsExternalAllow(state,action){
      state.isExternalAllow = action.payload;
    },
    setIsReadOnly(state, action) {
      state.isReadOnly = action.payload;
    },
    setDarkMode(state, actions){
      state.darkMode = actions.payload;
    },
    setIsMasterSlide(state, action) {
      state.isMasterSlide = action.payload;
    },
    setMasterSlideId(state, action) {
      state.masterSlideId = action.payload;
    },
    setMasterSlideIdElementId(state, action) {
      state.masterSlideElementId = action.payload;
    },
    addDeletedElementId(state,action){
      const {elementId,elementData} = action.payload;
      state.deletedElements[elementId] = elementData;
    },
    removeDeletedElementId(state, action) {
      delete state.deletedElements[action.payload];
    },
    updateElement(state, action) {
      const { slideId, elementId, elementData, type } = action.payload;
      if (type === 'textbox' && slideId && state.slides[slideId] && state.slides[slideId].textBox[elementId]) {
        state.slides[slideId].textBox[elementId].delta = elementData.delta;
        state.slides[slideId].textBox[elementId].styles = elementData.styles;
      }
      else if (type === 'image' && slideId && state.slides[slideId] && state.slides[slideId].image[elementId]) {
        state.slides[slideId].image[elementId] = elementData;
      }
      else if (type === 'shape' && slideId && state.slides[slideId] && state.slides[slideId].shapes[elementId]) {
        state.slides[slideId].shapes[elementId] = elementData;
      }
    },
    setUserInformation(state, action) {
      state.userInformation = action.payload;
    },
    createNewPresentation(slideState, actions) {
      let { currentSlideId, presentMode, mirrorSlides, slides } = actions.payload;
      if (!mirrorSlides) {
        mirrorSlides = {};
      }
      // slideState.presentationId = presentationId;
      slideState.currentSlideId = currentSlideId;
      slideState.presentMode = presentMode;
      slideState.mirrorSlides = mirrorSlides;
      if (slides) {
        Object.keys(slides).forEach((item) => {
          if (!slides[item].image) {
            slides[item].image = {};
          }
          if (!slides[item].shapes) {
            slides[item].shapes = {};
          }
          if (!slides[item].speakerNotes) {
            slides[item].speakerNotes = "";
          }
          // if(!slides[item].textBox){
          //   slides[item].textBox = {};
          // }
          // if(!slides[item].textBoxIds){
          //   slides[item].textBoxIds = [];
          // }
        });
      }
      slideState.slides = slides;
    },
    addTextBox(slideState, actions) {
      const { textId, style, currentSlideId } = actions.payload;
      slideState.slides[currentSlideId].textBoxIds.push(textId);
      slideState.slides[currentSlideId].textBox[textId] = {
        delta: actions.payload.delta ? actions.payload.delta : {
          ops: [{ insert: "" }]
        },
        styles: style
      }
      // createNewElementInDatabase(slideState.presentationId,currentSlideId,textId,{ delta: {
      //   ops: [{insert:""}]
      // },styles: style}, 'text');
    },
    // addData(slideState, actions) {
    //   slideState.slideData[slideState.currentSlideIndex].text[slideState.currentTextBoxIndex].value =
    //     actions.payload;
    // },
    setCurrentSlide(state, actions) {
      state.currentSlideId = actions.payload;
      if (!state.isMasterSlide) {
        saveCurrentSlideIdInDatabase(state.presentationId, actions.payload);
      }
    },
    setPresentMode(state, actions) {
      state.presentMode = actions.payload;
    },
    setSlideBackgroundColor(state, actions) {
      const { slideId, backgroundColor, isChangedByUser } = actions.payload;
      state.slides[slideId].slideBackgroundColor = backgroundColor;
      if (isChangedByUser) {
        state.slides[slideId].additionalData = { 
          ...state.slides[slideId].additionalData, 
          isSlideColorChanged: isChangedByUser 
        };
      }
      // if(!state.currentSlideId.includes('master')){
      //   updateSlideBackgroundInDatabase(state.presentationId, slideId, backgroundColor);
      // }
    },
    // setCurrentElement(state, actions) {
    //   state.currentElementId = actions.payload;
    // },
    updateStyle(slideState, actions) {
      const { slideId, textBoxId, updatedStyle } = actions.payload; // textboxid can also be shape id or image id 
      if (textBoxId.includes('text') || textBoxId.includes('title')) {
        slideState.slides[slideId].textBox[textBoxId].styles = updatedStyle;
        // if(!slideState.isMasterSlide){
        //   updateElementStyleInDatabase(slideState.presentationId, slideId, textBoxId, updatedStyle, 'text');
        // }
      }
      else if (textBoxId.includes('image')) {
        slideState.slides[slideId].image[textBoxId].styles = updatedStyle;
        // if(!slideState.isMasterSlide){
        //   updateElementStyleInDatabase(slideState.presentationId, slideId, textBoxId, updatedStyle, 'image');
        // }
      }
      else if (textBoxId.includes('shape')) {
        slideState.slides[slideId].shapes[textBoxId].styles = updatedStyle;
        // if(!slideState.isMasterSlide){
        //   updateElementStyleInDatabase(slideState.presentationId, slideId, textBoxId, updatedStyle, 'shape');
        // }
      }
      // textBoxId.substring(0,5) !== 'image' ? slideState.slides[slideState.currentSlideId].textBox[textBoxId].styles = updatedStyle : slideState.slides[slideId].image[textBoxId].styles = updatedStyle;
    },
    // removeStyle(slideState,actions){
    //   delete slideState.slideData[slideState.currentSlideIndex].text[slideState.currentTextBoxIndex].styles[actions.payload]
    //   // console.log(slideState.slideData[slideState.currentSlideIndex].text[slideState.currentTextBoxIndex].styles);
    // },
    updateDelta(state, actions) {
      // console.log("inside update Delta",actions.payload)
      const { slideId, content, activeQuillInstanceId } = actions.payload;
      if (slideId && state.slides[slideId] && state.slides[slideId].textBox[activeQuillInstanceId] && state.slides[slideId].textBox[activeQuillInstanceId].delta)
        state.slides[slideId].textBox[activeQuillInstanceId].delta = content;
      // updateElementTextInDatabase(state.presentationId, slideId, activeQuillInstanceId, content);
    },
    addImage(state, actions) {
      const { imageId, imageb64, style, currentSlideId } = actions.payload;
      state.slides[currentSlideId].imageIds.push(imageId);
      state.slides[currentSlideId].image[imageId] = { imageb64: imageb64, styles: style }
      // console.log(state.slides[state.currentSlideId].image);
      // createNewElementInDatabase(state.presentationId, currentSlideId, imageId, {imageb64: imageb64, styles: {width: '20%', height:'20%', position: 'absolute', top:'50%', left: '50%'}}, 'image');
    },
    addSpeakerNotes(state, actions) {
      const { slideId, content } = actions.payload;
      state.slides[slideId].speakerNotes = content;
      // console.log(emitFlag);
      // if(emitFlag){
      //   emitSpeakerNotes({roomId: state.presentationId, notesData: {slideId: slideId, content: content}});
      //   updateSpeakerNotesInDatabase(state.presentationId, slideId, content);
      // }
      // updateSpeakerNotesInDatabase(state.presentationId,slideId, content);
    },

    importPresentation(state) {
      state.currentSlideId = "";
      state.mirrorSlides = {};
      state.slides = {};
      // state.slides = action.payload;

      // console.log(state.slides);
      // for(const prop in state.slides){
      //   state.currentSlideId = prop;
      //   console.log("property=>>",prop, "currentSlide===>",state.currentSlideId);
      // }
    },
    saveMirror(state, actions) {
      const { dataId, mirrorSlideDataUrl } = actions.payload;
      // state.mirrorSlides = {...state.mirrorSlides, [dataId]: mirrorSlideDataUrl }
      if (dataId) {
        if(!state.currentSlideId.includes('master')){
          state.mirrorSlides[dataId] = mirrorSlideDataUrl;
          if(!state.isMasterSlide){
            saveMirrorInDatabase(state.presentationId, dataId, mirrorSlideDataUrl);
          }
        }
      }
    },
    removeTextBox(state, action) {
      const { slideId, textBoxId } = action.payload;
      state.slides[slideId].textBoxIds = state.slides[slideId].textBoxIds.filter(textBox => textBox !== textBoxId);
      delete state.slides[slideId].textBox[textBoxId];
      // deleteElementFromDatabase(state.presentationId,slideId,textBoxId, 'text');
    },
    removeImage(state, action) {
      const { slideId, imageId } = action.payload;
      state.slides[slideId].imageIds = state.slides[slideId].imageIds.filter(image => image !== imageId);
      delete state.slides[slideId].image[imageId];
      // deleteElementFromDatabase(state.presentationId, slideId, imageId, 'image');
    },
    removeSlide(state, action) {
      const slideId = action.payload;
      const slideKeys = Object.keys(state.slides).map(key => key);
      const nextIndex = slideKeys.indexOf(slideId) === slideKeys.length - 1 ? slideKeys.indexOf(slideId) - 1 : slideKeys.indexOf(slideId) + 1;
      if (state.currentSlideId === action.payload) {
        state.currentSlideId = slideKeys[nextIndex] ? slideKeys[nextIndex] : "";
      }

      delete state.mirrorSlides[slideId];
      delete state.slides[slideId];
      // deleteSlideFromDatabase(state.presentationId,slideId, state.currentSlideId);
    },
    addShape(slideState, actions) {
      const { shapeId, style, currentSlideId } = actions.payload;
      // slideState.slides[slideState.currentSlideId].textBoxIds.push(textId)
      slideState.slides[currentSlideId].shapes[shapeId] = {
        delta: actions.payload.delta ? actions.payload.delta : {
          ops: [{ insert: "" }]
        },
        styles: style
      }
      // createNewElementInDatabase(slideState.presentationId, currentSlideId, shapeId,{ delta: {
      //   ops: [{insert:""}]
      // },styles: style},'shape');
    },
    removeShape(slideState, actions) {
      const { slideId, shapeId } = actions.payload;
      delete slideState.slides[slideId].shapes[shapeId];
      // deleteElementFromDatabase(slideState.presentationId,slideId,shapeId, 'shape');
    },
    updateShapeText(state, actions) {
      const { slideId, content, shapeId } = actions.payload;
      if(state.slides[slideId] && state.slides[slideId].shapes[shapeId] && state.slides[slideId].shapes[shapeId].delta)
        state.slides[slideId].shapes[shapeId].delta = content;
      // updateShapeTextInDatabase(state.presentationId, slideId, shapeId, content);
    },
    setPresentationId(state, actions) {
      state.presentationId = actions.payload;
    },
    loadPresentation(state, actions) {
      let { currentSlideId, mirrorSlides, slides } = actions.payload;
      let existingMasterSlideId;

      if (slides) {
        existingMasterSlideId =  Object.keys(slides).find(slideId => slideId.toLowerCase().includes("master"));
        if (currentSlideId.toLowerCase().includes("master")) {
          const slideIds = Object.keys(slides);
          let i = slideIds.length - 1;
          while (i >= 0 && slideIds[i].toLowerCase().includes("master")) {
            i--;
          }
          if (i >= 0) {
            currentSlideId = slideIds[i];
          }
        }
        Object.keys(slides).forEach((item) => {
          if (!slides[item].text) {
            slides[item].text = {};
          }
          if (!slides[item].image) {
            slides[item].image = {};
          }
          if (!slides[item].shapes) {
            slides[item].shapes = {};
          }
          if (!slides[item].textBox) {
            slides[item].textBox = {};
          }
          if (!slides[item].textBoxIds) {
            slides[item].textBoxIds = [];
          }
          // if(!slides[item].textBox){
          //   slides[item].textBox = {};
          // }
        })
      } else {
        slides = {}
      }
      state.currentSlideId = currentSlideId;
      if (mirrorSlides) {
        state.mirrorSlides = mirrorSlides;
      }
      state.slides = slides;
      if (existingMasterSlideId) {
        state.masterSlideId = existingMasterSlideId;
        //  state.isMasterSlide = true;
      }


      // console.log("slides",slides,currentSlideId);
      // state.currentSlideId = "hahatest";
      // if (slides.image === undefined) {
      //   state.slides = slides;  
      //   state.slides[currentSlideId].image = {};
      // } else {
      // }
      // if(slides.speakerNotes === undefined){
      //   state.slides[currentSlideId].speakerNotes = ""

      // }


    },
    moveSlideToBeginning: (state, action) => {
      const slideId = action.payload;
      const slideOrder = Object.keys(state.slides);
      const index = slideOrder.indexOf(slideId);
      if (index !== -1) {
        const [movedSlide] = slideOrder.splice(index, 1);
        // slideOrder.unshift(movedSlide);
        // const movedSlides = state.slides[movedSlide];
        // state.slides = {
        //   [slideId]: movedSlides,
        //   ...state.slides,
        // }
        const movedMirrorSlide = state.mirrorSlides[movedSlide];
        delete state.mirrorSlides[movedSlide];
        state.mirrorSlides = {
          [slideId]: movedMirrorSlide,
          ...state.mirrorSlides,
        };
        // saveMirrorInDatabase(state.presentationId,slideId, state.mirrorSlides)
      }
    },
    moveSlideToEnd: (state, action) => {
      const slideId = action.payload;
      const slideOrder = Object.keys(state.slides);
      const index = slideOrder.indexOf(slideId);
      if (index !== -1) {
        const [movedSlide] = slideOrder.splice(index, 1);
        //  slideOrder.push(movedSlide);
        // const updatedSlides = {};
        // slideOrder.forEach((id) => {
        //   updatedSlides[id] = state.slides[id];
        // });
        // state.slides = updatedSlides;
        const movedMirrorSlide = state.mirrorSlides[movedSlide];
        delete state.mirrorSlides[movedSlide];
        state.mirrorSlides = {
          ...state.mirrorSlides,
          [slideId]: movedMirrorSlide,
        };
      }
    },
    removeDocumentMember(state,action){
      state.documentInformation.members = state.documentInformation?.members?.filter((member) => member.id !== action.payload);
    }
  },
});

export const importPresentation = (param) => {
  let i = 0;
  return async (dispatch) => {
    dispatch(slideAction.importPresentation());
    function myLoop() {         //  create a loop function
      setTimeout(function () {   //  call a 3s setTimeout when the loop is called
        dispatch(slideAction.addSlide({ slideId: `slide${i}`, slideData: param[`slide${i}`] }));
        console.log(param[`slide${i}`], i);
        dispatch(slideAction.setCurrentSlide(`slide${i}`));
        i++;                    //  increment the counter
        if (i < Object.keys(param).length) {           //  if the counter < 10, call the loop function
          myLoop();             //  ..  again which will trigger another 
        }                       //  ..  setTimeout()
      }, 700)
    }
    myLoop();
  };
}
//   export const loadPresentation = (param) => {
//     let i = 0;
//     return async(dispatch) => {
//         dispatch(slideAction.loadSlide());
//         function myLoop() {         //  create a loop function
//           setTimeout(function() {   //  call a 3s setTimeout when the loop is called
//             dispatch(slideAction.addSlide({slideId:`slide${i}`, slideData: param[`slide${i}`]}))
//             i++;                    //  increment the counter
//             if (i < Object.keys(param).length) {           //  if the counter < 10, call the loop function
//               myLoop();             //  ..  again which will trigger another 
//             }                       //  ..  setTimeout()
//           }, 700)
//         }
//         myLoop();
//     };
// }





export const slideAction = slideSlice.actions; // export slideActions which have reducer method names as keys to access actions

export default slideSlice.reducer;