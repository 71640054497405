import { Avatar, message, Button, Modal } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import "./shareForm.css";
import defaultProfileImage from "../../../assets/defult-profile-logo.png";
import { slideAction } from "../../../store/slideSlice";
import { workwiseServices } from "../../WorkW-Api/workWiseApi";

const PeopleWithAccess = () => {
    const dispatch = useDispatch();
    const documentInformation = useSelector((state) => state.slide.documentInformation);
    const userInformation = useSelector((state) => state.slide.userInformation);
    const [showAll, setShowAll] = useState(false); 

    const handleRemoveMember = async (member) => {
        try {
            // Api call for remove reader rights for specific user, privacy id "1" represents reader rights
            await workwiseServices.removeDocumentMember({
                documentId: documentInformation?.referenceId,
                status: 1,
                documentMembers: [member.id]
            });

            // Api call for remove collaborator rights for specific user, privacy id "2" represents collaborator rights
            await workwiseServices.removeDocumentMember({
                documentId: documentInformation?.referenceId,
                status: 2,
                documentMembers: [member.id]
            });
            dispatch(slideAction.removeDocumentMember(member.id));
        } catch (error) {
            console.error("Error removing document member:", error);
            message.error(error.response?.data?.message || "Failed to remove member.");
        }
    };

    const members = documentInformation?.members?.filter(member => member.id !== userInformation?.userId) || [];
    const visibleMembers = showAll ? members : members.slice(0, 3);

    return (
        <div className="doc-member-container">
            {visibleMembers.map((member) => (
                <div
                    key={member.id}
                    className="member-option"
                    style={{ display: "flex", alignItems: "center", justifyContent: "space-between", gap: 8, padding: "8px", borderBottom: "1px solid #ddd" }}
                >
                    <div style={{ display: "flex", gap: 8, alignItems: "center" }}>
                        <Avatar size="large" src={member.image || defaultProfileImage} />
                        <div className="member-info">
                            <span className="name" style={{ fontWeight: "500" }}>
                                {member.name}
                            </span>
                            <br />
                            <span className="designation">{member.designation}</span>
                        </div>
                    </div>
                    {/* Remove Icon */}
                    <CloseOutlined
                        onClick={() => handleRemoveMember(member)}
                        style={{ cursor: "pointer", color: "red", fontSize: "16px" }}
                    />
                </div>
            ))}
            {/* Show View All Button if members exceed 10 */}
            {members.length > 3 && (
                <Button type="link" onClick={() => setShowAll(!showAll)} style={{ float: 'right' }}>
                    {showAll ? "Show Less" : `View All (${members.length})`}
                </Button>
            )}
        </div>
    );
};

export default PeopleWithAccess;
