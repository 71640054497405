import React, { useRef, useState } from 'react';
import { Avatar, AutoComplete, Image, Form, Select, Button, message } from 'antd';
import { workwiseServices } from '../../WorkW-Api/workWiseApi';
import { debounce } from 'lodash';
import defaultProfileImage from '../../../assets/defult-profile-logo.png';
import { useSelector,useDispatch } from 'react-redux';
import { slideAction } from '../../../store/slideSlice';
import './shareForm.css'
import { useParams } from 'react-router-dom/cjs/react-router-dom';
import PeopleWithAccess from './peopleWithAccess';

const { Option } = Select;

const ShareForm = ({ setIsModalVisible, setPrivacy }) => {
    const dispatch=useDispatch()
    const {id}=useParams()
    const [rightType, setRightType] = useState(null);
    const [selectedMember, setSelectedMember] = useState({});
    const [options, setOptions] = useState([]);
    const [inputValue, setInputValue] = useState('');
    const documentInformation = useSelector((state) => state.slide.documentInformation);
    const isMobile = window.screen.width < 767;
    const debouncedGetAllSuggestions = useRef(
        debounce(async (payload) => {
            try {
                const res = await workwiseServices.getAllSuggestion(payload);
                const data = res.data.data;
                const filteredData = data.filter((member) =>
                    !documentInformation.members.some((docMember) => docMember.id === member.id)
                );

                const optionsData = filteredData.map((member) => ({
                    key: member.id,
                    value: member.name,
                    label: (
                        <div className="member-option" style={{ display: 'flex', gap: 8 }}>
                            <div>
                                <Avatar
                                    size="large"
                                    src={
                                        member.image ? (
                                            <Image src={member.image} />
                                        ) : (
                                            <Image
                                                src={defaultProfileImage}
                                                style={{ width: '35px' }}
                                            />
                                        )
                                    }
                                />
                            </div>
                            <div className="member-info">
                                <span className="name" style={{ fontWeight: '500' }}>
                                    {member.name}
                                </span>
                                <br />
                                <span className="designation">{member.designation}</span>
                            </div>
                        </div>
                    ),
                }));
                setOptions(optionsData);
            } catch (error) {
                message.error('something went wrong...');
            }
        }, 300)
    ).current;

    const handleSearch = (value) => {
        setInputValue(value);
        if (value !== '') {
            const payload = {
                notRequired: [],
                pageNo: 1,
                pageSize: 20,
                referenceId: '00000000-0000-0000-0000-000000000000',
                removeSelf: true,
                search: value,
                types: [1],
            }
            debouncedGetAllSuggestions(payload);
        }
        else {
            debouncedGetAllSuggestions.cancel();
            setOptions([]);
            setSelectedMember({});
        }
    };

    const handleSelect = (value) => {
        const selectedUser = options.find((user) => user.value === value);
        if (selectedUser) {
            setSelectedMember(selectedUser);
        }
    };

    const handleRights = (value) => {
        if (value === 'collaborator') {
            setRightType(2);
        } else if (value === 'reader') {
            setRightType(1);
        }
    };
    const handleAddMember = async() => {
        if (!selectedMember.key && rightType === null) {
            message.error('select member & right type.');
            return;
        }
        else if (selectedMember.value !== inputValue) {
            message.error('select member.');
            return;
        }
        else if (rightType === null) {
            message.error('select right type.');
            return;
        }
        const payload = [{
            id: documentInformation.referenceId,
            memberId: selectedMember.key,
            memberRightType: rightType
        }];
        try {
            await workwiseServices.addDocumentMember(documentInformation.referenceId, payload);
            setIsModalVisible(false);
            setPrivacy(0);
            message.success("Document shared successfully");

        } catch (e) {
            message.error("Something went wrong....");
        }
        const response = await workwiseServices.getDocumentById(id);
        if (response?.status == 200 && response.data?.data?.rightType !== 0 && response.data?.data) {
            dispatch(slideAction.setDocumentInformation(response.data.data));
        }
    };

    return (
        <div className="restricted-container">
             <PeopleWithAccess />
             <Form className="share-form"  onFinish={handleAddMember}>
                <div style={{display:'flex',gap:8}}>
                    <Form.Item style={{flex:1}}>
                        <AutoComplete
                            className='share-input'
                            options={options}
                            onSelect={handleSelect}
                            onSearch={handleSearch}
                            onChange={setInputValue}
                            placeholder="Share with"
                            notFoundContent={'No data'}
                            dropdownStyle={{
                                width: 'auto',
                                minWidth: options.length == 0 ? 'auto' : '180px'
                            }}
                        // style={{ width: '120px' }}

                        />
                    </Form.Item>
                    <Form.Item style={{flex:1}} className='select-input' >
                        <Select onChange={handleRights} placeholder="Rights" dropdownStyle={{ minWidth: '100px' }}>
                            <Option value="reader">Reader</Option>
                            <Option value="collaborator">Collaborator</Option>
                        </Select>
                    </Form.Item>
                </div>
                <Button style={{ position:'relative',right:isMobile?'30px':'',width:'fit-content', display: 'block', backgroundColor: '#526bb1', color:'#ffffff', borderRadius: "8px"}} size="middle" className='share-button' htmlType="submit">
                    Share
                </Button>
            </Form>
        </div>
    );
};

export default ShareForm;
