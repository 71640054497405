import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from "react-router-dom";
import { useDispatch, useSelector } from 'react-redux';
import MainLayout from '../MainLayout';
import { slideAPI } from '../../store/api';
import Loader from '../SkeletonLoader/Loader';
import { v4 } from "uuid";
import { slideAction } from '../../store/slideSlice';

const External = () => {
    const { id } = useParams();
    const history = useHistory();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(true);
    const isVerified = useSelector((state) => state.slide.isExternal);

    useEffect(() => {
        (async () => {
            try {
                const result = await slideAPI.verifyExternal(id);
                dispatch(slideAction.setIsExternal(true));
                dispatch(slideAction.setUserInformation({
                    userId: `external-${v4()}`,
                    email: 'N/A',
                    name: 'External User'
                }));
                dispatch(slideAction.setDocumentInformation(result?.data));
            } catch (error) {
                console.error("API error:", error);
                history.push('/unauthorized');
            } finally {
                setLoading(false);
            }
        })();
    }, [id]);

    return loading ? <Loader /> : isVerified ? <MainLayout /> : null;
};

export default External;
