import { Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import "./style.css";

export const DropDownMenu = ({ menuItems, placement, type, setType }) => {
  const typeLabels = {
    1: "Restricted",
    0: "Anyone",
  };
  const selectedLabel = typeLabels[type] || "Unknown";

  const handleItemClick = (item) => {
    setType(item.key);
  };

  const menu = (
    <Menu>
      {menuItems.map((item) => (
        <Menu.Item key={item.key} onClick={() => handleItemClick(item)}>
          <span>{item.label}</span>
        </Menu.Item>
      ))}
    </Menu>
  );


  return (
    <div className="share-option-container">
      <Dropdown overlay={menu} trigger={["click"]} placement={placement}>
        <p className="ant-dropdown-link" onClick={(e) => e.preventDefault()}>
          {selectedLabel} <DownOutlined />
        </p>
      </Dropdown>
    </div>
  );
};
