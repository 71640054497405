import axios from "axios";
import { slideAction } from "./slideSlice";
import { workwiseServices } from "../components/WorkW-Api/workWiseApi";
import { message } from "antd";
import { formatFileSize } from "../utils/helper";

// export const serverUrl = "http://192.168.87.21:8080";
export const serverUrl = "https://slides.workw.com";

export const slideAPI = {
  fetchPresentationById: async (presentationId) => {
    return axios.get(`${serverUrl}/api/${presentationId}`);
  },
  createPresentationById: async (presentationId, dispatch, setIsContentLoading) => {
    try {
      const res = await axios.post(`${serverUrl}/api/create`, { presentationId });
      if (res.status === 200) {
        setIsContentLoading(false);
        if (res.data && res.data[0]) {
          dispatch(slideAction.setIsExternalAllow(res?.data[0]?.isExternal));
          dispatch(slideAction.loadPresentation(res.data[0].slideData));
          if (dispatch) {
            const size = new TextEncoder().encode(JSON.stringify(res.data[0].slideData)).length || 0;
            const sizeWithUnit = formatFileSize(size);
            dispatch(slideAction.updateIsSaving({ isSaving: '', lastSavedDateTime: '', fileSize: sizeWithUnit }));
          }
        } else if (res.data) {
          dispatch(slideAction.createNewPresentation(res.data.slideData));
        }
      }
    } catch (error) {
      setIsContentLoading(false);
      message.error("Something went wrong...");
      console.error('Error creating presentation:', error);
    }
  },

  saveToDatabase: async (SlidesID, currentElement, currentSlideId, SlidesData) => {
    return axios.post(`${serverUrl}/api/update`, {
      SlidesID: SlidesID.id,
      //currentElementId,
      //currentSlideId,
      //elementData,
    });
  },
  deletePresentationFromDatabase: async (presentationId) => {
    return axios.post(`${serverUrl}/api/delete`, presentationId);
  },
  async createNewElementInDatabase(presentationId, currentSlideId, newElementId, newElementData, elementType, dispatch, data) {
    try {
      const res = axios.post(`${serverUrl}/api/create/element`, {
        presentationId,
        currentSlideId,
        newElementId,
        newElementData,
        elementType,
      })
      if (!currentSlideId.includes('master') && dispatch) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        await new Promise(resolve => setTimeout(resolve, 800));
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    }
    catch (e) {
      console.error('Something went worng...')
    }
  },
  updateElementTextInDatabase: async (presentationId, currentSlideId, currentElementId, updatedData, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/update/element/text`, {
        presentationId,
        currentSlideId,
        currentElementId,
        updatedData,
      });
      if (!currentSlideId.includes('master') && dispatch) {
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    }
    catch (error) {
      console.error('Error saving data:', error);
    }
  },
  updateShapeTextInDatabase: async (presentationId, currentSlideId, currentElementId, updatedData, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/update/shape/text`, {
        presentationId,
        currentSlideId,
        currentElementId,
        updatedData,
      });
      if (!currentSlideId.includes('master') && dispatch) {
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    } catch (error) {
      console.error('Error saving data:', error);
    }
  },
  updateElementStyleInDatabase: async (presentationId, currentSlideId, currentElementId, updatedStyle, elementType, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/update/element/style`, {
        presentationId,
        currentSlideId,
        currentElementId,
        updatedStyle,
        elementType, //string
      });
      if (!currentSlideId.includes('master') && dispatch) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        await new Promise(resolve => setTimeout(resolve, 800));
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    }
    catch (error) {
      console.error('Error saving data:', error);
    }
  },
  deleteElementFromDatabase: async (presentationId, currentSlideId, currentElementId, elementType, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/delete/element`, {
        presentationId,
        currentSlideId,
        currentElementId,
        elementType, //string
      });
      if (!currentSlideId.includes('master') && dispatch) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        await new Promise(resolve => setTimeout(resolve, 800));
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    } catch (error) {
      console.error('Error saving data:', error);
    }
  },
  addNewSlideInDatabase: async (presentationId, slideId, slideData, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/create/slide`, {
        presentationId,
        slideId,
        slideData,
      });
      if (!slideId.includes('master') && dispatch) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        await new Promise(resolve => setTimeout(resolve, 800));
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    }
    catch (error) {
      console.error('Error saving data:', error);
    }
  },
  saveMirrorInDatabase: async (presentationId, imageId, b64Data) => {
    return axios.post(`${serverUrl}/api/mirror`, {
      presentationId,
      imageId,
      b64Data,
    })
  },
  deleteSlideFromDatabase: async (presentationId, slideId, currentSlideId, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/delete/slide`, {
        presentationId,
        slideId,
        currentSlideId,
      });
      if (!currentSlideId.includes('master') && dispatch) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        await new Promise(resolve => setTimeout(resolve, 800));
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    }
    catch (error) {
      console.error('Error saving data:', error);
    }
  },
  updateSlideBackgroundInDatabase: async (presentationId, slideId, color, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/update/slide/backgroundcolor`, {
        presentationId,
        slideId,
        color,
      });
      if (!slideId.includes('master') && dispatch) {
        dispatch(slideAction.updateIsSaving({ isSaving: 'Saving...', lastSavedDateTime: '' }));
        await new Promise(resolve => setTimeout(resolve, 800));
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    }
    catch (error) {
      console.error('Error saving data:', error);
    }
  },
  updateSpeakerNotesInDatabase: async (presentationId, slideId, speakerNotes, dispatch, data) => {
    try {
      const res = axios.post(`${serverUrl}/api/update/speakerNotes`, {
        presentationId,
        slideId,
        speakerNotes,
      });
      if (!slideId.includes('master') && dispatch) {
        workwiseServices.saveDocumentlastUpdate(presentationId, dispatch, data);
      }
      return res;
    } catch (error) {
      console.error('Error saving data:', error);
    }
  },
  saveCurrentSlideIdInDatabase: async (presentationId, slideId) => {
    return axios.post(`${serverUrl}/api/update/currentSlideId`, {
      presentationId,
      slideId,
    })
  },
  saveMultipleMirrorInDatabase: async (presentationId, images) => {
    return axios.post(`${serverUrl}/api/mirror-multiple`, {
      presentationId,
      images
    })
  },
  updateMultipleSlideBackgroundInDatabase: async (presentationId, backgroundColor, layoutType = null) => {
    return axios.post(`${serverUrl}/api/update/slide/backgroundcolor-multiple`, {
      presentationId,
      color: backgroundColor,
      layoutType: layoutType
    })
  },
  async createMultipleNewElementInDatabase(presentationId, newElementId, newElementData, elementType, layoutType = null) {
    return axios.post(`${serverUrl}/api/create/element-multiple`, {
      presentationId: presentationId,
      newElementId: newElementId,
      newElementData: newElementData,
      elementType: elementType,
      layoutType: layoutType

    });
  },
  updateMultipleElementTextInDatabase: async (presentationId, currentElementId, updatedData, layoutType = null) => {
    return axios.post(`${serverUrl}/api/update/element/text-multiple`, {
      presentationId: presentationId,
      currentElementId: currentElementId,
      updatedData: updatedData,
      layoutType: layoutType
    });
  },
  updateMultipleElementStyleInDatabase: async (presentationId, currentElementId, updatedStyle, elementType, layoutType = null) => {
    return axios.post(`${serverUrl}/api/update/element/style-multiple`, {
      presentationId: presentationId,
      currentElementId: currentElementId,
      updatedStyle: updatedStyle,
      elementType: elementType,
      layoutType: layoutType
    });
  },
  saveImportedDataToDb: async (data) => {
    return await axios.post(`${serverUrl}/api/import/internal`, {...data});
  },
  verifyExternal: async (id) => {
      const response = await axios.get(`${serverUrl}/api/external/verify/${id}`);
      return response.data;
  },
  updateDocumentPrivacy: async (data) => {
      const response = await axios.post(`${serverUrl}/api/external/privacyUpdate`,data);
      return response.data;
  },
};
