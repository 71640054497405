import React, { useState } from "react";
import { Modal } from "antd";
import "antd/dist/antd.css";
import { ShareModalContent } from "./ShareModalContent/ShareModalContent";
import ShareBtn from '../../assets/share-file.svg';
import './ShareModal.css';

export const ShareModal = ({ shareUrl }) => {
  const isMobile=window.screen.width<768
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [privacy, setPrivacy] = useState(0);

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
    setPrivacy(0);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
    setPrivacy(0);
  };

  return (
    <>
      {/* <Button type="primary" onClick={showModal} style={{ backgroundColor: '#1a5669', border: '1px solid #1a5669' }}> */}
      <div onClick={showModal} style={{top: "10px", cursor: "pointer", zIndex: 1 }} >
        <img src={ShareBtn} style={{ height:isMobile?'20px': "25px"}} />
      </div>
      {/* <span style={{fontWeight: "500", letterSpacing: "0.5px"}}>Share</span> */}
      {/* </Button> */}
      <Modal
        title="Share with people and Groups"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        footer={false}
        className="share-doc-modal"
      >
        <ShareModalContent shareUrl={shareUrl} setIsModalVisible={setIsModalVisible} privacy={privacy} setPrivacy={setPrivacy} />
      </Modal>
    </>
  );
};
